import {
  EditOutlined, PlusOutlined, SearchOutlined
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Switch,
  Table,
  notification
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import LocalizationFormItem from "app/components/Locale/LocalizationFormItem";
import { Compliance } from "app/models/Compliance";
import complianceServices from "app/services/compliance.service";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableData, TableFilter } from "../../models/Table";

interface TableParams extends SorterResult<Compliance> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CompliancesPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [data, setData] = useState<Compliance[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [compliance, setCompliance] = useState<Compliance>();
  const [form] = Form.useForm();

  const getData = async () => {
    setLoading(true);
    try {
      const tableFilters: TableFilter = {
        sortDir: tableParams.order != "descend" ? "ASC" : "DESC",
        sortProperty: tableParams.field?.toString(),
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
      };
      const rs: TableData = await complianceServices.list(tableFilters);
      setData(rs.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: rs.totalItems,
        },
      });
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (compliance) {
      form.setFieldsValue(compliance);
    } else {
      form.resetFields();
    }
  }, [compliance]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<Compliance>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getCompliance = async (id: number) => {
    try {
      const rs = await complianceServices.detail(id);
      setCompliance(rs);
    } catch (error) {}
  };

  const showModal = (id?: number) => {
    setIsModalOpen(true);
    if (id) {
      getCompliance(id);
    }
  };

  const onComplianceSubmitted = async (values: Compliance) => {
    try {
      if (values.id) {
        await complianceServices.update(values);
        let index = data?.findIndex((m) => m.id == values.id);
        data[index] = { ...data[index], ...values };
        setData([...data]);
      } else {
        const rs = await complianceServices.create(values);
        const newData = [rs, ...data];
        setData(newData);
      }
      handleCancel();
      api.success({
        message: `Update success!`,
        description: <></>,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: `Update failed!`,
        description: t("error.default"),
        placement: "topRight",
      });
    }
  };

  const handleCancel = () => {
    setCompliance(undefined);
    setIsModalOpen(false);
  };

  const columns: ColumnsType<Compliance> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_: any, record: Compliance) => (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={showModal.bind(null, record.id)}
        ></Button>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <h3>{t("menu.compliances")}</h3>
      <div className="d-flex gap-3 mb-3 w-100 app-form">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal.bind(null, 0)}
        >
          {t("compliance.add")}
        </Button>
        {/* <Input
          className="ms-auto"
          style={{
            maxWidth: "20vw",
          }}
          prefix={<SearchOutlined />}
          placeholder={t("compliance.search.placeholder") || ""}
          allowClear
          // onChange={onSearch}
        /> */}
      </div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `surface-${record.id}`}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title={t("menu.compliances")}
        open={isModalOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onComplianceSubmitted}
          initialValues={{
            active: true,
          }}
        >
          <div className="row mt-3">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <div className="col">
              <Form.Item label={t("name")} name="name">
                <Input />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default CompliancesPage;
