import { createSlice } from "@reduxjs/toolkit";

export const materialSlice = createSlice({
  name: "material",
  initialState: {
    groups: [],
    cuttingMachineGroups: [],
    thicknesses: [],
    compliances: []
  },
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setCuttingMachineGroups: (state, action) => {
      state.cuttingMachineGroups = action.payload
    },
    setThicknesses: (state, action) => {
      state.thicknesses = action.payload
    },
    setCompliances: (state, action) => {
      state.compliances = action.payload
    }
  },
});


export const materialActions = materialSlice.actions
export const materialSelector = (state: any) => state.material;
export default materialSlice.reducer;
