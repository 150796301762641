import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next from "i18next";
import moment from "moment";
import { Form, FormInstance, Input } from "antd";
import ENIcon from "../SVGs/ENIcon";
import DEIcon from "../SVGs/DEIcon";
import { useEffect, useState } from "react";
import { configSelector } from "app/redux/slides/config.slide";
import ITIcon from "../SVGs/ITIcon";
import CZIcon from "../SVGs/CZIcon";

interface DefaultProps {
  form: FormInstance<any>;
  data: any;
}
const LocalizationFormItem = (props: DefaultProps) => {
  const { form, data } = props;
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  // const locales = config.locales || [];
  const [locales, setLocales] = useState<any[]>([]);

  const setFormData = (data: any, index: number) => {
    if (form) {
      form.setFieldValue(["localizations", index, "id"], data.id);
      form.setFieldValue(["localizations", index, "locale"], data.locale);
      form.setFieldValue(["localizations", index, "name"], data.name);
    }
  };
  useEffect(() => {
    locales.map((item, index) => {
      setFormData(item, index);
    });
  }, [locales]);
  useEffect(() => {
    if (data && form) {
      const locales: any[] = [...data.localizations];

      config.locales.map((key: string) => {
        const index = locales.findIndex(
          (l: any) => l.locale.toLowerCase() === key
        );
        if (index < 0) {
          locales.push({ locale: key.toUpperCase(), name: "" });
        }
      });
      setLocales(locales);
    }
  }, [data, form]);

  const flagIcon = (key: any) => {
    switch (key) {
      case "en":
        return <ENIcon width={"1vw"} height={"1vw"} />;
      case "de":
        return <DEIcon width={"1vw"} height={"1vw"} />;
      case "it":
        return <ITIcon width={"1vw"} height={"1vw"} />;
      case "cs":
        return <CZIcon width={"1vw"} height={"1vw"} />;
      default:
        break;
    }
  };
  return (
    <>
      <p className="text-label mb-2">{t("localizedNames")}</p>
      {locales.map((item: any, index: any) => (
        <div key={`localizations-${item.locale}`}>
          <Form.Item name={["localizations", index, "id"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={["localizations", index, "locale"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name={["localizations", index, "name"]}
            rules={[
              { required: config.locales?.includes(item.locale.toLowerCase()) },
            ]}
            messageVariables={{
              label: `${item.locale.toUpperCase()} ${t("name")}`,
            }}
            hidden={!config.locales?.includes(item.locale.toLowerCase())}
          >
            <Input prefix={flagIcon(item.locale.toLowerCase())} />
          </Form.Item>
        </div>
      ))}
    </>
  );
};

export default LocalizationFormItem;
