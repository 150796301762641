import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import LoadingComponent from "../Loading/LoadingComponent";
import partServices from "../../services/part.service";
import { CostReport } from "app/models/CostReport";
import CurrencyFormat from "../Format/CurrencyFormat";

interface DefaultProps {
  partId?: number;
  projectId?: number | string;
  onAdapted?: any;
  onCancel?: any;
  readonly?: boolean;
}

function CostReportModal(props: DefaultProps) {
  const { partId, onAdapted, onCancel, readonly = false } = props;
  const { t } = useTranslation();
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [costReport, setCostReport] = useState<CostReport>();

  const getData = async () => {
    setLoading(true);
    try {
      const rs = await partServices.getCostReport(partId);
      setCostReport(rs);
    } catch (error) {}
    setLoading(false);
  };

  const renderCost = (cost: any) => {
    if (cost == undefined) {
      return <>
      <div className="col-6">
            <CurrencyFormat value={0} />
      </div>
      </>
    }
    return (
      <>
        {cost.toFixed(2) > 0.0 && (
          <div className="col-6">
            <CurrencyFormat value={cost} />
          </div>
        )}
        {cost.toFixed(2) == 0.0 && (
          <div className="col-6">
            <CurrencyFormat
              value={cost}
              maximumFractionDigits={6}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <LoadingComponent loading={loading} />
      <Button onClick={setIsOpenReport.bind(null, true)}>
        <FileTextOutlined /> {t("costReport")}
      </Button>
      <Modal
        open={isOpenReport}
        title={t("costReport")}
        width={"65vw"}
        className="modal-body-scroll"
        onCancel={setIsOpenReport.bind(null, false)}
        footer={
          <Row>
            <Col span={12} style={{ textAlign: "start" }}>
              <b>{t("price.unitPrice")}</b>:{" "}
              <CurrencyFormat value={costReport?.unitPrice} />
            </Col>
            <Col span={12}>
              <Button
                onClick={setIsOpenReport.bind(null, false)}
                type="default"
              >
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.rawMaterial")}</h6>
          </Divider>
          <div className="mini-group">
            <div className="row">
              <div className="col col-lg-12">
                <label className="fw-bold">{t("costReport.dimensions")}:</label>
                <span>
                  &nbsp; {costReport?.length?.toFixed(2)} x{" "}
                  {costReport?.height?.toFixed(2)} x{" "}
                  {costReport?.thickness?.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col col-lg-6">
                <label className="fw-bold">{t("costReport.weight")}:</label>
                <span>&nbsp; {costReport?.rawMaterialWeight?.toFixed(2)}</span>
              </div>
              <div className="col col-lg-6 pb-2">
                <label className="fw-bold">{t("costReport.costs")}:</label>
                <span>
                  &nbsp; <CurrencyFormat value={costReport?.materialCosts} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.setup")}</h6>
          </Divider>
          {costReport?.cuttingSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.laser")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.cuttingSetupTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.cuttingSetupCosts)}
              </div>
            </div>
          )}

          {costReport?.foldingSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.bending")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.foldingSetupTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.foldingSetupCosts)}
              </div>
            </div>
          )}

          {costReport?.deburringSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.deburring")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.deburringSetupTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.deburringSetupCosts)}
              </div>
            </div>
          )}

          {costReport?.threadCuttingSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.thread")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.threadCuttingSetupTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.threadCuttingSetupCosts)}
              </div>
            </div>
          )}

          {costReport?.countersinkSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.countersinks")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.countersinkSetupTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.countersinkSetupCosts)}
              </div>
            </div>
          )}

          {costReport?.trovalizeSetupCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.trovalize")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.trovalizeSetupTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.trovalizeSetupCosts)}
              </div>
            </div>
          )}
        </div>

        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.programming")}</h6>
          </Divider>
          {costReport?.cuttingProgrammingTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.laser")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.cuttingProgrammingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.cuttingProgrammingCosts)}
              </div>
            </div>
          )}

          {costReport?.foldingProgrammingTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.bending")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.foldingProgrammingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.foldingProgrammingCosts)}
              </div>
            </div>
          )}
        </div>

        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.processing")}</h6>
          </Divider>

          {costReport?.cuttingProcessingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.laser")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.cuttingProcessingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.cuttingProcessingCosts)}
              </div>
            </div>
          )}

          {costReport?.foldingProcessingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.bending")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.foldingProcessingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.foldingProcessingCosts)}
              </div>
            </div>
          )}

          {costReport?.deburringProcessingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.deburring")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.deburringProcessingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.deburringProcessingCosts)}
              </div>
            </div>
          )}

          {costReport?.threadProcessingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.thread")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.threadProcessingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.threadProcessingCosts)}
              </div>
            </div>
          )}

          {costReport?.counterSinkProcessingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.countersinks")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.counterSinkProcessingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.counterSinkProcessingCosts)}
              </div>
            </div>
          )}

          {costReport?.laserMarkingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("part.form.laserMarking")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.laserMarkingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.laserMarkingCosts)}
              </div>
            </div>
          )}

          {costReport?.trovalizeProcessingCosts && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.trovalize")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.trovalizeProcessingTime?.toFixed(2)}
                </div>
                {renderCost(costReport?.trovalizeProcessingCosts)}
              </div>
            </div>
          )}
        </div>

        {costReport?.surfaceTreatment && (
          <div>
            <Divider orientation="left" orientationMargin={0}>
              <h6>{t("costReport.surfaceTreatment")}</h6>
            </Divider>
            <div className="mini-group">
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              {costReport?.surfaceTreatment.map((item: any) => (
                <div key={item.method} className="row border-bottom pt-2 pb-2">
                  <div className="col-6">{item.method}</div>
                  {renderCost(item?.surfaceTreatmentCost)}
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default CostReportModal;
