const jsonToFormData = (data: any) => {
  const formData = new FormData();
  Object.keys(data).map((key) => {
    if (data[key] === null || data[key] === undefined) return false;
    if (typeof data[key] === "object" && !(data[key] instanceof File) && !Array.isArray(data[key])) {
      data[key] = JSON.stringify(data[key]);
    }
    formData.append(key, data[key]);
  });
  return formData;
};

const getTrimmedData = (data: any) => {
  const obj = { ...data };
  if (obj && typeof obj === "object") {
    Object.keys(obj).map((key) => {
      if (typeof obj[key] === "object") {
        getTrimmedData(obj[key]);
      } else if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim().replace(/\s+/g, " ");
      }
    });
  }
  return obj;
};

const triggerTypeNumber = (event: any) => {
  if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
    event.preventDefault();
  }
};

const parseFileName = (fileName: string) => {
  return {
    name: fileName.substring(0, fileName.lastIndexOf("."))?.toLowerCase(),
    ext: fileName.split(".").pop()?.toLowerCase()
  }
}

const Utils = {
  jsonToFormData,
  getTrimmedData,
  triggerTypeNumber,
  parseFileName
};

export default Utils;
