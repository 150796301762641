import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";
import { ArrowDownUp } from "react-bootstrap-icons";

interface DefaultProps {
  value?: number;
  adaptPrice?: number;
  isHideAdapt?: boolean;
  showZero?: boolean;
  hiddenValue?: boolean;
  hideCurrency?: boolean;
  maximumFractionDigits?: number
}
const numberFormat = (value: any, hideCurrency: boolean = false, maximumFractionDigits: number = 2) =>
  new Intl.NumberFormat("de-DE", {
    style: hideCurrency ? "decimal" : "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value.toFixed(4));
const CurrencyFormat = (props: DefaultProps) => {
  const { t } = useTranslation();
  const {
    value = null,
    adaptPrice = null,
    isHideAdapt = false,
    showZero,
    hiddenValue = false,
    hideCurrency = false,
    maximumFractionDigits = 2
  } = props;
  if (hiddenValue || (!value && adaptPrice === null)) return <>-- €</>;
  if (adaptPrice === 0) return <>{numberFormat(0, hideCurrency, maximumFractionDigits)} </>;
  if (value !== null && adaptPrice === null) return <>{numberFormat(value, hideCurrency, maximumFractionDigits)} </>;
  if (value == null && adaptPrice !== null) return <>{numberFormat(adaptPrice, hideCurrency, maximumFractionDigits)} </>;

  return (
    <>
      {!isHideAdapt && (
        <span className="">
          {value !== null &&
            adaptPrice !== null &&
            numberFormat(value, hideCurrency, maximumFractionDigits) !=
              numberFormat(adaptPrice, hideCurrency, maximumFractionDigits) && (
              <Popover
                placement="left"
                content={
                  <>
                    <p className="mb-2 fw-bold">{t("originalPrice")}</p>
                    <CurrencyFormat value={value} />
                  </>
                }
                trigger="hover"
              >
                <span className="d-flex justify-content-end align-items-center fw-bold">
                  <>
                    <ArrowDownUp />
                    &nbsp;
                  </>
                  {numberFormat(adaptPrice, hideCurrency, maximumFractionDigits)}
                </span>
              </Popover>
            )}
          {value !== null &&
            (adaptPrice === null ||
              numberFormat(value, hideCurrency, maximumFractionDigits) ==
                numberFormat(adaptPrice, hideCurrency, maximumFractionDigits)) &&
            numberFormat(value, hideCurrency, maximumFractionDigits)}
        </span>
      )}
      {isHideAdapt && numberFormat(adaptPrice || value, hideCurrency)}
    </>
  );
};

export default CurrencyFormat;
