import services from ".";
import { API } from "../constants/api.constant";
import { Login } from "../models/Login";
import { ResetPassword } from "../models/ResetPassword";
import { User } from "../models/User";

export const login = async (data: Login) => {
  try {
    const rs = await services.post(API.LOGIN, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};
export const register = async (data: User) => {
  try {
    const rs = await services.post(API.REGISTER, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const verify = async (verificationCode: string) => {
  try {
    const rs = await services.post(API.VERIFY, { verificationCode });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const rs = await services.post(API.FORGOT_PASSWORD, { email });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data: ResetPassword) => {
  try {
    const rs = await services.post(API.RESET_PASSWORD, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (data: {
  password: string;
  newPassword: string;
}) => {
  try {
    const rs = await services.post(API.CHANGE_PASSWORD, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const me = async () => {
  try {
    const rs = await services.get(API.ME);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const updateAccount = async (data: User) => {
  try {
    const rs = await services.post(API.ACCOUNT_SETTINGS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const findUser = async (email: string) => {
  try {
    const rs = await services.get(API.USERS_BY_EMAIL, {
      params: { email },
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const changeLanguage = async (locale: string) => {
  try {
    const rs = await services.post(API.USER_CHANGE_LANGUAGE, {
      locale
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const addFavourite = async (materialId: number) => {
  try {
    const rs = await services.post(
      API.MATERIAL_ADD_FAVOURITE.replace(":id", materialId.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};
export const removeFavourite = async (materialId: number) => {
  try {
    const rs = await services.delete(
      API.MATERIAL_REMOVE_FAVOURITE.replace(":id", materialId.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const userServices = {
  login,
  register,
  verify,
  forgotPassword,
  resetPassword,
  changePassword,
  me,
  updateAccount,
  findUser,
  changeLanguage,
  addFavourite,
  removeFavourite
};

export default userServices;
