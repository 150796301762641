import React, { useEffect, useState } from "react";
import { Form, notification, Select, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { TableFilter } from "../../models/Table";
import surfaceTreatmentServices from "../../services/surfaceTreatment.service";
import { User } from "../../models/User";
import customerServices from "../../services/customer.service";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "app/components/Format/CurrencyFormat";
import moment from "moment";
const { Option } = Select;

interface TableParams extends SorterResult<User> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CustomerPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const rs = await customerServices.list();
      setData(rs);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<User> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: t("fullName"),
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: t("customer.summary.parts"),
      dataIndex: "numParts",
      sorter: (a: any, b: any) => a.numParts - b.numParts,
    },
    {
      title: t("customer.summary.order"),
      dataIndex: "orderCostSum",
      sorter: (a: any, b: any) => a.orderCostSum - b.orderCostSum,
      render: (data: any) => <CurrencyFormat value={data} />,
    },
    {
      title: t("customer.summary.calculations"),
      dataIndex: "numCalculations",
      sorter: (a: any, b: any) => a.numCalculations - b.numCalculations,
    },
    {
      title: t("customer.form.discount"),
      dataIndex: "discount",
      sorter: (a: any, b: any) => a.discount - b.discount,
    },
    {
      title: t("customer.createdAt"),
      dataIndex: "createdAt",
      sorter: (a: any, b: any) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (data) => moment(data).format("L"),
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_: any, record: User) => (
        <Link to={`/seller/customers/${record.id}`}>
          <EyeOutlined />
        </Link>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <h3>{t("customer.heading")}</h3>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `customer-${record.id}`}
        dataSource={data}
        // pagination={tableParams.pagination}
        loading={loading}
        // onChange={handleTableChange}
      />
    </>
  );
};

export default CustomerPage;
