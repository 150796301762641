import {
  CheckOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  LineOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Form, Input, Button, Popover, InputNumber, notification, Space } from "antd";
import { t } from "i18next";
import { CurrencyEuro } from "react-bootstrap-icons";
import CurrencyFormat from "../Format/CurrencyFormat";
import { Part } from "../../models/Part";
import {
  HTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import projectServices from "../../services/project.service";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import { useDispatch, useSelector } from "react-redux";

interface DefaultProps extends HTMLAttributes<any> {
  part: Part;
  onAdapted?: any;
}

function AdaptForm(props: DefaultProps, ref: any) {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const project = useSelector(projectSelector).data;
  const { onAdapted } = props;
  const data = props.part;
  const [part, setPart] = useState<Part>(data);
  const [adaptForm] = Form.useForm();
  const [adaptPrice, setAdaptPrice] = useState<any>(
    (part?.adaptedUnitPrice || part?.unitPrice)?.toFixed(2)
  );
  const [originalPrice, setOriginalPrice] = useState(
    part?.unitPrice?.toFixed(2)
  );
  const [isOpenOriginalPrice, setIsOpenOriginalPrice] = useState(false);
  const [loadingAdaptPrice, setLoadingAdaptPrice] = useState(false);

  useImperativeHandle(ref, () => ({
    updateAdapt: (priceAdapted: number) => {
      adaptForm.setFieldValue("unitPrice", priceAdapted?.toFixed(2));
    },
  }));
  const onPriceAdaptReset = () => {
    adaptForm.setFieldValue(
      "unitPrice",
      Number(part.unitPrice?.toFixed(2)) || ""
    );
    setAdaptPrice(part?.unitPrice?.toFixed(2));
  };

  useEffect(() => {
    if (data) {
      setPart(data);
    }
  }, [data]);

  useEffect(() => {
    if (!project.adapted) {
      onPriceAdaptReset();
    }
  }, [JSON.stringify(project)]);

  const onPriceAdapt = (value: any) => {
    setAdaptPrice(value?.toFixed(2));
  };

  const onOpenOriginalPrice = () => {
    setIsOpenOriginalPrice(!!part?.unitPrice);
  };
  const onCloseOriginalPrice = () => {
    setIsOpenOriginalPrice(false);
  };
  const onAdaptPriceSubmit = async (values: any) => {
    setLoadingAdaptPrice(true);
    try {
      const rs = await projectServices.adaptPartPrice(values);
      dispatch(projectActions.setProject(rs));
      // const p: Part = { ...part, adaptedUnitPrice: Number(values.unitPrice) };
      // setPart(p);
      setAdaptPrice(values.unitPrice);
      if (onAdapted) {
        const p: Part = {
          ...part,
          adaptedUnitPrice: values.unitPrice,
          totalPrice: values.unitPrice * part.quantity,
        };
        onAdapted(p);
      }
      api.info({
        message: `Adapted price`,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: `Adapt price failed!`,
        placement: "topRight",
      });
    }
    setLoadingAdaptPrice(false);
  };

  return (
    <>
      {contextHolder}
      <Form
        form={adaptForm}
        id="adaptForm"
        initialValues={{
          partId: part.id,
          unitPrice: part.adaptedUnitPrice || part.unitPrice || "",
        }}
        className={`ms-auto app-form ${props.className}`}
        onFinish={onAdaptPriceSubmit}
      >
        <Form.Item name="partId" hidden>
          <Input />
        </Form.Item>
        <Space.Compact className="d-flex">
          <Form.Item className="mb-0">
            <Button
              type="default"
              icon={<UndoOutlined />}
              disabled={part.unitPrice?.toFixed(2) === adaptPrice}
              onClick={onPriceAdaptReset}
            />
          </Form.Item>
          <Popover
            placement="top"
            content={
              <>
                <p className="mb-2 fw-bold">{t("originalPrice")}</p>
                <CurrencyFormat value={part?.unitPrice} />
              </>
            }
            trigger="click"
            open={isOpenOriginalPrice}
          >
            <Form.Item
              messageVariables={{ label: t("price") }}
              name="unitPrice"
              className="mb-0"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                type="numer"
                precision={2}
                style={{ width: "10em" }}
                min={1}
                addonAfter={<CurrencyEuro />}
                onChange={onPriceAdapt}
                onFocus={onOpenOriginalPrice}
                onBlur={onCloseOriginalPrice}
                decimalSeparator=","
                prefix={
                  <>
                    {Number(originalPrice) < Number(adaptPrice) && (
                      <ArrowUpOutlined style={{ color: "red" }} />
                    )}
                    {Number(originalPrice) > Number(adaptPrice) && (
                      <ArrowDownOutlined style={{ color: "green" }} />
                    )}
                    {Number(originalPrice) === Number(adaptPrice) && (
                      <LineOutlined style={{ color: "transparent" }} />
                    )}
                  </>
                }
              />
            </Form.Item>
          </Popover>
          <Form.Item className="mb-0">
            <Button
              type="primary"
              htmlType="submit"
              icon={<CheckOutlined />}
              loading={loadingAdaptPrice}
              disabled={
                (part?.adaptedUnitPrice || part?.unitPrice)?.toFixed(2) ==
                adaptPrice
              }
            />
          </Form.Item>
        </Space.Compact>
      </Form>
    </>
  );
}
const PriceAdaptForm = forwardRef(AdaptForm);
export default PriceAdaptForm;
