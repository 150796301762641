import { Alert, Button, Form, Input, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slides/auth.slide";
import { useState, HTMLAttributes, useRef, useEffect } from "react";
import { loadingActions } from "../../redux/slides/loading.slide";
import projectServices from "../../services/project.service";
import { useNavigate } from "react-router-dom";
interface DefaultProps extends HTMLAttributes<any> {}
function AddProjectAutoNameButton(props: DefaultProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const [api, contextHolder] = notification.useNotification();

  const handleAddProject = async () => {
    dispatch(loadingActions.show(true));
    try {
      const rs = await projectServices.createAutoName();
      navigate(`/${auth.user.role}/projects/${rs.id}/calculations`);
    } catch (error: any) {
      api.error({
        message: "Internal Server Error!",
        placement: "topRight",
      });
    }
    dispatch(loadingActions.show(false));
  };

  return (
    <>
      {contextHolder}
      <Button
        type="primary"
        onClick={handleAddProject}
        {...props}
        className={`btn-add-project ${props.className}`}
      >
        <PlusOutlined /> {t("addProject")}
      </Button>
    </>
  );
}
export default AddProjectAutoNameButton;
