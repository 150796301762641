import { lazy, Suspense } from "react";
import { Button, Card, Col, Row } from "antd";
import "./customDxfSheetMetal.module.scss";

import { useState } from "react";
import ShapeGridLines from "../Shape/ShapeGridLines";
import { ShapeTypes } from "../Shape/ShapeConstants";
import { useParams } from "react-router";
import partServices from "app/services/part.service";
import LoadingComponent from "../Loading/LoadingComponent";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  LAYOUT_BREAK_POINT,
  ShapeConfig,
  ShapeConfigMobile,
} from "../Shape/ShapeConstants";
import { useTranslation } from "react-i18next";

const RoundGasket = lazy(
  () => import("app/components/Shape/RoundGasket/RoundGasket")
);
const RoundGasketForm = lazy(
  () => import("app/components/Shape/RoundGasket/RoundGasketForm")
);

const RectangleGasket = lazy(
  () => import("app/components/Shape/RectangleGasket/RectangleGasket")
);
const RectangleGasketForm = lazy(
  () => import("app/components/Shape/RectangleGasket/RectangleGasketForm")
);

const OvalGasket = lazy(
  () => import("app/components/Shape/OvalGasket/OvalGasket")
);
const OvalGasketForm = lazy(
  () => import("app/components/Shape/OvalGasket/OvalGasketForm")
);

const ElongatedGasket = lazy(
  () => import("app/components/Shape/ElongatedGasket/ElongatedGasket")
);
const ElongatedGasketForm = lazy(
  () => import("app/components/Shape/ElongatedGasket/ElongatedGasketForm")
);

const RectanglePanelWrapper = lazy(
  () => import("app/components/Shape/RectanglePanel/RectanglePanelWrapper")
);
const RectanglePanelForm = lazy(
  () => import("app/components/Shape/RectanglePanel/RectanglePanelForm")
);
const RectangleRoundedPanelForm = lazy(
  () => import("app/components/Shape/RectanglePanel/RectangleRoundedPanelForm")
);
const RectangleRoundedTopPanelForm = lazy(
  () =>
    import("app/components/Shape/RectanglePanel/RectangleRoundedTopPanelForm")
);

const CirclePanel = lazy(
  () => import("app/components/Shape/CirclePanel/CirclePanel")
);
const CirclePanelForm = lazy(
  () => import("app/components/Shape/CirclePanel/CirclePanelForm")
);

const EllipsePanel = lazy(
  () => import("app/components/Shape/CirclePanel/EllipsePanel")
);
const EllipsePanelForm = lazy(
  () => import("app/components/Shape/CirclePanel/EllipsePanelForm")
);

const HalfCirclePanel = lazy(
  () => import("app/components/Shape/CirclePanel/HalfCirclePanel")
);
const HalfCirclePanelForm = lazy(
  () => import("app/components/Shape/CirclePanel/HalfCirclePanelForm")
);

const OctagonPanel = lazy(
  () => import("app/components/Shape/OctagonPanel/OctagonPanel")
);
const OctagonPanelForm = lazy(
  () => import("app/components/Shape/OctagonPanel/OctagonPanelForm")
);

const TrapezPanel = lazy(
  () => import("app/components/Shape/TrapezPanel/TrapezPanel")
);
const TrapezPanelForm = lazy(
  () => import("app/components/Shape/TrapezPanel/TrapezPanelForm")
);

const IsoTrapezPanel = lazy(
  () => import("app/components/Shape/IsoTrapezPanel/IsoTrapezPanel")
);
const IsoTrapezPanelForm = lazy(
  () => import("app/components/Shape/IsoTrapezPanel/IsoTrapezPanelForm")
);

interface CustomDxfSheetMetalProps {
  onGenerate?: any;
}

const CustomDxfSheetMetal = (props: CustomDxfSheetMetalProps) => {
  const { t } = useTranslation();
  const { onGenerate } = props;
  const [shapeConfig, setShapeConfig] = useState<any>(null);
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const size = useWindowSize();
  let config: any = ShapeConfig;
  if (size.width < LAYOUT_BREAK_POINT) {
    config = ShapeConfigMobile;
  }

  const onSelectShape = (index: any, selected: boolean) => {
    if (selected) {
      return;
    }
    setShapeConfig(null);
    setSelectedShape(gasketList[index]);
  };

  const handleOnChangeForm = (value: any, valid: any) => {
    if (typeof valid != "undefined") {
      setIsValid(valid);
    }

    if (valid == true) {
      setShapeConfig(value);
    }
  };

  const gasketList = [
    {
      name: "Round",
      shapeType: ShapeTypes.ROUND,
      image: "/images/shapes/round.png",
      component: <RoundGasket {...shapeConfig} />,
      form: (
        <RoundGasketForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Rectangle gasket",
      shapeType: ShapeTypes.RECTANGLE,
      image: "/images/shapes/square-gasket.png",
      component: <RectangleGasket {...shapeConfig} />,
      form: (
        <RectangleGasketForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Oval gasket",
      shapeType: ShapeTypes.OVAL,
      image: "/images/shapes/oval-gasket.png",
      component: <OvalGasket {...shapeConfig} />,
      form: (
        <OvalGasketForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Elongated/round gasket",
      shapeType: ShapeTypes.ELONGATED,
      image: "/images/shapes/elongated-round-gasket.png",
      component: <ElongatedGasket {...shapeConfig} />,
      form: (
        <ElongatedGasketForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Rectangle panel",
      shapeType: ShapeTypes.RECTANGLE_PANEL,
      image: "/images/shapes/rect.svg",
      component: <RectanglePanelWrapper {...shapeConfig} />,
      form: (
        <RectanglePanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Rectangle rounded panel",
      shapeType: ShapeTypes.RECTANGLE_ROUNDED_PANEL,
      image: "/images/shapes/roundrect.svg",
      component: <RectanglePanelWrapper {...shapeConfig} />,
      form: (
        <RectangleRoundedPanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Rectangle rounded top panel",
      shapeType: ShapeTypes.RECTANGLE_TOP_ROUNDED_PANEL,
      image: "/images/shapes/rect2r.svg",
      component: <RectanglePanelWrapper {...shapeConfig} />,
      form: (
        <RectangleRoundedTopPanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Circle panel",
      shapeType: ShapeTypes.CIRCLE_PANEL,
      image: "/images/shapes/circle.svg",
      component: <CirclePanel {...shapeConfig} />,
      form: (
        <CirclePanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Ellipse panel",
      shapeType: ShapeTypes.ELLIPSE_PANEL,
      image: "/images/shapes/ellipse.svg",
      component: <EllipsePanel {...shapeConfig} />,
      form: (
        <EllipsePanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Half Circle panel",
      shapeType: ShapeTypes.HALF_CIRCLE_PANEL,
      image: "/images/shapes/halfcircle.svg",
      component: <HalfCirclePanel {...shapeConfig} />,
      form: (
        <HalfCirclePanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Octagon",
      shapeType: ShapeTypes.OCTAGON_PANEL,
      image: "/images/shapes/octa.svg",
      component: <OctagonPanel {...shapeConfig} />,
      form: (
        <OctagonPanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Trapez",
      shapeType: ShapeTypes.TRAPEZ_PANEL,
      image: "/images/shapes/trapez.svg",
      component: <TrapezPanel {...shapeConfig} />,
      form: (
        <TrapezPanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
    {
      name: "Isosceles trapezoid",
      shapeType: ShapeTypes.ISO_TRAPEZ_PANEL,
      image: "/images/shapes/trapez2.svg",
      component: <IsoTrapezPanel {...shapeConfig} />,
      form: (
        <IsoTrapezPanelForm
          parentHandleOnChange={handleOnChangeForm}
          config={config}
        />
      ),
    },
  ];

  const [selectedShape, setSelectedShape] = useState<any>(gasketList[0]);

  const handleSubmitCustomDxf = async () => {
    setIsLoading(true);
    try {
      let formData = shapeConfig;

      if (!projectId) {
        return;
      }

      const rs = await partServices.postCustomSheetMetalConfig(
        formData,
        projectId,
        selectedShape.shapeType
      );

      onGenerate(rs);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }

    setIsLoading(false);
  };

  return (
    <div className={`mt-4 custom-dxf`} style={{ position: "relative" }}>
      <LoadingComponent loading={isLoading} />
      <Row gutter={16}>
        <Col
          xs={24}
          sm={4}
          className="mt-3"
          style={{ display: "flex", maxHeight: 573, minHeight: 573 }}
        >
          <Card style={{ overflowY: "scroll" }}>
            <Row gutter={16}>
              {gasketList &&
                gasketList.map((item, index) => (
                  <Col span={12} key={`shape-${index}`}>
                    <img
                      style={{ width: "100%" }}
                      className={`justify-content-center mt-3 card-gasket img-thumbnail ${
                        item.name == selectedShape.name ? "selected" : ""
                      }`}
                      onClick={() =>
                        onSelectShape(index, item.name == selectedShape.name)
                      }
                      alt={item.name}
                      src={item.image}
                    />
                  </Col>
                ))}
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={20} style={{}} className="mt-3 shape-config">
          <Card className="" style={{ minHeight: "400px" }}>
            <Row gutter={16} className="mt-3">
              <Col xs={24} sm={11}>
                <Suspense>{selectedShape && selectedShape.form}</Suspense>
              </Col>
              <Col
                xs={24}
                sm={13}
                style={{ height: "100%", display: "flex" }}
                className="justify-content-center"
              >
                {selectedShape && (
                  <ShapeGridLines
                    selectedShape={selectedShape}
                    shapeConfig={shapeConfig}
                    config={config}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div className="mt-3 justify-content-end" style={{ display: "flex" }}>
        <Button
          disabled={shapeConfig == null || isLoading || !isValid}
          type="primary"
          onClick={handleSubmitCustomDxf}
        >
          {t("calculation.nextStep")}
        </Button>
      </div>
    </div>
  );
};

export default CustomDxfSheetMetal;
