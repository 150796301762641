import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    captchaEnabled: false,
    keySiteCaptcha: "",
    shopType: "",
    gaTrackingCode: "",
    gtmTrackingCode: "",
    sellerMail: "",
    locales: [],
    paymentEnabled: false
  },
  reducers: {
    setData: (state, action) => {
      state.shopType = action.payload.shopType;
      state.captchaEnabled = action.payload.captchaEnabled;
      state.keySiteCaptcha = action.payload.keySiteCaptcha;
      state.gaTrackingCode = action.payload.gaTrackingCode;
      state.gtmTrackingCode = action.payload.gtmTrackingCode;
      state.sellerMail = action.payload.sellerMail;
      state.locales = action.payload.locales;
      state.paymentEnabled = action.payload.paymentEnabled;
    },
  },
});

export const configActions = configSlice.actions;
export const configSelector = (state: any) => state.config;
export default configSlice.reducer;
