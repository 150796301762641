import { ReactNode, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Switch } from "antd";
import { useTranslation } from "react-i18next";
// import "../paymentModal.module.scss";
import { CreditCardOutlined, FileDoneOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { UserRole } from "app/models/UserRole";
import { Project, ProjectStatus } from "app/models/Project";
import TextArea from "antd/es/input/TextArea";
import projectServices from "app/services/project.service";

interface DefaultProps {
  children?: ReactNode;
  project: Project;
  triggerShowModal?: boolean;
  hideButton?: boolean;
  onModalClose?: any;
  onComplete: any;
}

const ProjectListManualPaymentFormModal = (props: DefaultProps) => {
  const { project, triggerShowModal, hideButton, onModalClose, onComplete } =
    props;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    if (triggerShowModal != undefined) {
      setIsOpen(triggerShowModal);
    }
  }, [triggerShowModal]);

  const handlePayLater = () => {
    setIsOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  const handleOnCancel = () => {
    setIsOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  const onFinish = async () => {
    await projectServices.markAsPaid(
      project.id,
      form.getFieldValue("paymentComment")
    );

    if (onComplete) {
      onComplete();
    }
    setIsOpen(false);
  };

  if (
    auth?.user?.role != UserRole.SELLER ||
    !config?.paymentEnabled ||
    project?.order?.paid ||
    (project?.status != ProjectStatus.SELLER_APPROVED && project?.status != ProjectStatus.BUYER_APPROVED)
  ) {
    return <></>;
  }

  return (
    <div className="">
      {(!hideButton || hideButton == undefined) && (
        <Button type="primary" onClick={setIsOpen.bind(null, true)}>
          <FileDoneOutlined />
          {t("payment.markAsPaid")}
        </Button>
      )}
      <Modal
        open={isOpen}
        title={t("payment.markAsPaid")}
        width={"40vw"}
        className="modal-body-scroll payment-modal"
        onCancel={handleOnCancel}
        destroyOnClose
        bodyStyle={{ overflow: "auto" }}
        centered
        footer={
          <Row>
            <Col span={24}>
              <Button onClick={onFinish} type="primary">
                {t("save")}
              </Button>
              <Button onClick={setIsOpen.bind(null, false)} type="default">
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="d-flex flex-column">
          <Form
            form={form}
            name="update-payment"
            layout="vertical"
            initialValues={{ remember: false }}
            autoComplete="off"
          >
            <Form.Item
              label={t("payment.note")}
              name="paymentComment"
            >
              <TextArea maxLength={255} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectListManualPaymentFormModal;
