import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  FormProps,
  InputNumber,
  InputNumberProps,
  Space,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./inputInlineApi.module.scss";
import Utils from "app/utils";

interface DefaultProps extends FormProps {
  data: any;
}

const FormBulkPricingSetting = (props: DefaultProps) => {
  const { t } = useTranslation();
  const { data, ...formProps } = props;
  const [quantityRefs, setQuantityRefs] = useState({
    quantityRef1: data.quantityRef1,
    quantityRef2: data.quantityRef2,
    quantityRef3: data.quantityRef3,
  });

  const onFormChange = (values: any) => {
    const data = {
      ...quantityRefs,
      ...values,
    };
    setQuantityRefs(data);
  };
  return (
    <Form
      {...formProps}
      className="app-form"
      layout="vertical"
      name="basic"
      autoComplete="off"
      onValuesChange={onFormChange}
    >
      <div className="row">
        <div className="col col-12 col-xl-6">
          <Form.Item
            label={t("part.bulkPricing")}
            name="quantityRef1"
            extra={`${t("quantity")} ref 1`}
            messageVariables={{ label: `${t("quantity")} ref 1` }}
            rules={[
              { required: true },
              {
                validator: (_: any, value: any) => {
                  return ![quantityRefs?.quantityRef2, quantityRefs?.quantityRef3].includes(
                    value
                  )
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("validate.valueExisted") || "")
                      );
                },
              },
            ]}
          >
            <InputNumber
              onKeyDown={Utils.triggerTypeNumber}
              type="number"
              min={1}
              max={10000}
            />
          </Form.Item>
          <Form.Item
            name="quantityRef2"
            extra={`${t("quantity")} ref 2`}
            messageVariables={{ label: `${t("quantity")} ref 2` }}
            rules={[
              { required: true },
              {
                validator: (_: any, value: any) => {
                  return ![quantityRefs?.quantityRef1, quantityRefs?.quantityRef3].includes(
                    value
                  )
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("validate.valueExisted") || "")
                      );
                },
              },
            ]}
          >
            <InputNumber
              onKeyDown={Utils.triggerTypeNumber}
              type="number"
              min={1}
              max={10000}
            />
          </Form.Item>
          <Form.Item
            name="quantityRef3"
            extra={`${t("quantity")} ref 3`}
            messageVariables={{ label: `${t("quantity")} ref 3` }}
            rules={[
              { required: true },
              {
                validator: (_: any, value: any) => {
                  return ![quantityRefs?.quantityRef1, quantityRefs?.quantityRef2].includes(
                    value
                  )
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("validate.valueExisted") || "")
                      );
                },
              },
            ]}
          >
            <InputNumber
              onKeyDown={Utils.triggerTypeNumber}
              type="number"
              min={1}
              max={10000}
            />
          </Form.Item>
        </div>
      </div>
      <hr className="mt-3" />
      <div>
        <Button type="primary" htmlType="submit">
          {t("saveChanges")}
        </Button>
      </div>
    </Form>
  );
};

export default FormBulkPricingSetting;
