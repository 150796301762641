import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from "./de/translation.json";
import translationEN from "./en/translation.json";
import translationIT from "./it/translation.json";
import translationCS from "./cs/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  cs: {
    translation: translationCS,
  },
};

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    partialBundledLanguages: true,
    fallbackLng: "en",
    debug: process.env.REACT_APP_ENV === "development",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
