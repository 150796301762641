import { AutoComplete, Form, Input, Select } from "antd";
import { STATES } from "../../constants/state.constant";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import { useRuleStringRequired } from "app/utils/FormValidation";
// import countries from "./countries+states+cities.json";
import { useEffect, useState } from "react";
import axios from "axios";
import countryServices from "app/services/country.service";
import { FormInstance } from "antd/lib/form/Form";
import { Address } from "app/models/Address";

const { Option } = Select;
interface DefaultProps {
  form: FormInstance<any>;
  data?: Address;
  name?: string;
  title?: any;
  required?: boolean;
  disabled?: boolean;
}
const contriesApply = ["DE", "IT", "CZ"];
function AddressForm(props: DefaultProps) {
  const { t } = useTranslation();
  const {
    title = "",
    name = "",
    required = true,
    disabled = false,
    form,
    data,
  } = props;
  const ruleStringRequired = useRuleStringRequired(required);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState<any>();
  const [states, setStates] = useState([]);
  const [stateOptions, setStateOptions] = useState<{ value: string }[]>();
  const [state, setState] = useState<any>();
  const [cities, setCities] = useState<any[]>([]);
  const [cityOptions, setCityOptions] = useState<{ value: string }[]>([]);

  useEffect(() => {
    const countries = async () => {
      const rs: any = await countryServices.getCountries();
      setCountries(rs);
    };
    countries();
  }, []);

  useEffect(() => {
    if (data && countries.length) {
      const country: any = countries.find(
        (c: any) => c.iso2 == data.country || c.name == data.country
      );
      setCountry(country);
      if (country && data.state) {
        const state: any = country.states.find(
          (c: any) => c.name == data.state
        );
        if (state) {
          const cities = state.cities.map((c: any) => {
            return { value: c.name };
          });
          setCities(state.cities);
          setCityOptions(cities);
        }
      }
    }
  }, [data, countries]);
  useEffect(() => {
    if (country) {
      const states = country.states.map((s: any) => {
        return { value: s.name };
      });
      setStates(country.states);
      setStateOptions(states);
    }
  }, [country]);
  useEffect(() => {
    if (state) {
      const cities = state.cities.map((c: any) => {
        return { value: c.name };
      });
      setCities(state.cities);
      setCityOptions(cities);
    } else {
      setCities([]);
      setCityOptions([]);
    }
  }, [state]);

  const onCountryChanged = (value: any) => {
    const country: any = countries.find((c: any) => c.name == value);
    setCountry(country);
    setCities([]);
    setCityOptions([]);
    form.setFieldValue([name, "state"], "");
    form.setFieldValue([name, "city"], "");
  };
  const onStateChanged = (value: any) => {
    const state: any = states.find((c: any) => c.name == value);
    setState(state);
    form.setFieldValue([name, "city"], "");
  };

  const handleSearch = (key: "cities" | "states") => (value: string) => {
    let list: any[] = [];
    switch (key) {
      case "cities":
        list = cities.map((c: any) => {
          return { value: c.name };
        });
        setCityOptions(
          list.filter((item) =>
            item.value.toLowerCase().includes(value.toLowerCase())
          )
        );
        break;
      case "states":
        list = states.map((c: any) => {
          return { value: c.name };
        });
        setStateOptions(
          list.filter((item) =>
            item.value.toLowerCase().includes(value.toLowerCase())
          )
        );
        break;
    }
  };
  return (
    <>
      {title && <p className="sub-l">{title}</p>}
      <div className="row">
        <Form.Item name={[name, "id"]} hidden>
          <Input />
        </Form.Item>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("firstName")}
            name={[name, "firstName"]}
            rules={ruleStringRequired}
          >
            <Input maxLength={30} disabled={disabled} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("lastName")}
            name={[name, "lastName"]}
            rules={ruleStringRequired}
          >
            <Input maxLength={30} disabled={disabled} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.streetName")}
            name={[name, "streetName"]}
            rules={ruleStringRequired}
          >
            <Input maxLength={60} disabled={disabled} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.houseNumber")}
            name={[name, "houseNumber"]}
            rules={ruleStringRequired}
          >
            <Input maxLength={60} disabled={disabled} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.country")}
            name={[name, "country"]}
            rules={[{ required }]}
          >
            <Select
              showSearch
              placeholder=""
              disabled={disabled}
              onChange={onCountryChanged}
            >
              {countries.map((c: any) => (
                <Option key={`country-${c.iso2}`} value={c.name}>
                  {c.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            name={[name, "state"]}
            label={t("address.state")}
            rules={[
              {
                required: required && states.length > 0,
              },
            ]}
          >
            <AutoComplete
              options={stateOptions}
              onSearch={handleSearch("states")}
              onChange={onStateChanged}
              disabled={disabled}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.city")}
            name={[name, "city"]}
            rules={[
              {
                required: required && cities.length > 0,
              },
            ]}
          >
            <AutoComplete
              options={cityOptions}
              onSearch={handleSearch("cities")}
              disabled={disabled}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.postCode")}
            name={[name, "postCode"]}
            rules={ruleStringRequired}
          >
            <Input
              type="tel"
              maxLength={7}
              min={0}
              onKeyDown={Utils.triggerTypeNumber}
              disabled={disabled}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default AddressForm;
