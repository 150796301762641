import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { Price } from "app/models/Price";
import { QuickSetting } from "app/models/QuickSetting";
import quickSettingsServices from "app/services/quickSetting.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "../Format/CurrencyFormat";

interface DefaultProps {
  price?: Price;
  lableClass?: string;
  netPriceSurchargeHidden?: boolean
}

function TotalPartPrice(props: DefaultProps) {
  const { price, lableClass, netPriceSurchargeHidden = false } = props;
  const { t } = useTranslation();
  const [quickSetting, setQuickSetting] = useState<QuickSetting>();

  const getQuickSetting = async () => {
    try {
      const rs = await quickSettingsServices.detail();
      setQuickSetting(rs);
    } catch (error) {}
  };

  useEffect(() => {
    getQuickSetting();
  }, []);

  return (
    <>
      {!!price?.netPriceSurcharge && !netPriceSurchargeHidden && (
        <div className="d-flex">
          <label className={lableClass ?? lableClass}>
            {t("price.netPriceSurcharge")}{" "}
            <Popover
              placement="right"
              content={t("price.netPriceSurcharge.desc", {
                minimumOrderValue: quickSetting?.minimumOrderValue,
                minimumOrderValueForFastDelivery:
                  quickSetting?.minimumOrderValueForFastDelivery,
              })}
              trigger="hover"
            >
              <InfoCircleOutlined />
            </Popover>
            :
          </label>
          <label className="ms-auto">
            <CurrencyFormat value={price?.netPriceSurcharge} />
          </label>
        </div>
      )}
      <div className="d-flex">
        <label className={lableClass ?? lableClass}>
          {t("price.totalPartPrice")}:
        </label>
        <label className="ms-auto">
          <CurrencyFormat value={price?.totalPartPrice} />
        </label>
      </div>
    </>
  );
}

export default TotalPartPrice;
