import { useDispatch, useSelector } from "react-redux";
import "./error.module.scss";
import { authSelector } from "../../redux/slides/auth.slide";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { routers } from "../../constants/router.constant";
import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { loadingActions } from "../../redux/slides/loading.slide";

export function Error404Page() {
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(loadingActions.show(false));
    if (!auth.isLogined) navigate(routers.HOME);
  }, []);
  if (!auth.isLogined) return <></>;
  return (
    <div className="page-error">
      <h1>Oops!</h1>
      <h3>404 Not Found</h3>
      <p>Sorry, an error has occured, Requested page not found!</p>
      <Link to={auth.isLogined ? `/${auth.user?.role}` : routers.HOME}>
        <Button type="primary" icon={<HomeOutlined />}>
          Go home
        </Button>
      </Link>
    </div>
  );
}
