import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import { Button, Form, InputNumber, InputNumberProps, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./inputInlineApi.module.scss";

interface DefaultProps {
  label?: any;
  value?: any;
  name: string;
  callBack: Function;
  inputProps?: InputNumberProps;
  hiddenLoadingIcon?: boolean;
  existedValues?: any[];
}

const InputNumberInline = (props: DefaultProps) => {
  const {
    label = "Field",
    value,
    name,
    callBack,
    inputProps,
    existedValues,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tempValue, setTempValue] = useState("");
  const inputRef = useRef<any>(null);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      setTempValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      form.setFieldValue(name, value);
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleSetEditMode = () => {
    setIsEdit(true);
  };

  const handleInputBlur = async () => {
    const newValue = form.getFieldValue(`${name}`);
    console.log(newValue);
    
    if (tempValue == newValue) {
        setIsEdit(false)
        return
    }
    try {
      await form.validateFields();
      setIsLoading(true);
      await callBack(newValue);
      setIsLoading(false);
      setIsEdit(false);
    } catch (error) {}
  };

  const handleCloseEdit = () => {
    form.resetFields();

    setIsEdit(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Escape") {
      handleCloseEdit();
    }
    if (inputProps?.onKeyDown) {
      inputProps.onKeyDown(event);
    }
  };

  return (
    <div className="input-inline-api">
      {!isEdit && (
        <div className="text-input-value" onClick={handleSetEditMode}>
          <label>
            {tempValue} <EditOutlined />
          </label>
        </div>
        
      )}
      {!isEdit && !tempValue && <EditOutlined onClick={handleSetEditMode} />}
      {isEdit && (
        <Form form={form} name={`form-inline-${name}`}>
          <Form.Item
            className="m-0"
            initialValue={tempValue}
            name={`${name}`}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  return !existedValues || !existedValues.includes(`${value}`)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("validate.valueExisted") || "")
                      );
                },
              },
            ]}
            messageVariables={{
              label,
            }}
          >
            <InputNumber
              style={{ width: "100%" }}
              {...inputProps}
              disabled={isLoading}
              ref={inputRef}
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Space
            className="b-actions"
            wrap
            size={"small"}
          >
            <Button
              type="primary"
              icon={<CheckOutlined style={{ fontSize: "0.8rem" }} />}
              size={"small"}
              style={{ height: 35, width: 35 }}
              onClick={handleInputBlur}
              disabled={isLoading}
            />
            <Button
              icon={<CloseOutlined style={{ fontSize: "0.8rem" }} />}
              size={"small"}
              style={{ height: 35, width: 35 }}
              onMouseDown={handleCloseEdit}
              disabled={isLoading}
            />
          </Space>
        </Form>
      )}
    </div>
  );
};

export default InputNumberInline;
