/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Upload,
  notification,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import NumberFormat from "app/components/Format/NumberFormat";
import LocalizationFormItem from "app/components/Locale/LocalizationFormItem";
import LocalizationName from "app/components/Locale/LocalizationName";
import MaterialNumber from "app/components/Material/MaterialNumber";
import RawMaterialView from "app/components/Material/RawMaterialView";
import ThicknessView from "app/components/Material/ThicknessView";
import { Thickness } from "app/models/Thickness";
import thicknessServices from "app/services/thickness.service";
import { useRuleMaterialNumber } from "app/utils/FormValidation";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Material } from "../../models/Material";
import { MaterialGroup } from "../../models/MaterialGroup";
import { QuickSetting } from "../../models/QuickSetting";
import { ShopType } from "../../models/ShopType";
import {
  TableData,
  TableFilter,
  TableMaterialFilter,
  TableParams,
} from "../../models/Table";
import { configSelector } from "../../redux/slides/config.slide";
import materialServices from "../../services/material.service";
import quickSettingsServices from "../../services/quickSetting.service";
import "./material.module.scss";
import { Compliance } from "app/models/Compliance";
import complianceServices from "app/services/compliance.service";
import { setFormErrors } from "app/utils/Form";

const { Option } = Select;

const MaterialPage: React.FC = () => {
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState<Material[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalGroupOpen, setIsModalGroupOpen] = useState(false);
  const [material, setMaterial] = useState<Material>();
  const [materialGroups, setMaterialGroups] = useState<MaterialGroup[]>([]);
  const [materialGroup, setMaterialGroup] = useState<MaterialGroup>();
  const [uploading, setUploading] = useState(false);
  const [formMaterial] = Form.useForm();
  const [formGroup] = Form.useForm();
  const [formQuickSetting] = Form.useForm();
  const [quickSetting, setQuickSetting] = useState<QuickSetting>();
  const [isEditQuickSettings, setIsEditQuickSettings] = useState(false);
  const [text, setText] = useState("");
  const [thickness, setThickness] = useState();
  const [listThickness, setListThickness] = useState<Thickness[]>([]);
  const ruleMaterialNumber = useRuleMaterialNumber();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [modal, modalContextHolder] = Modal.useModal();
  const [materialDeleteId, setMaterialDeleteId] = useState<any>(undefined);
  const [showMaterialSettings, setShowMaterialSettings] = useState<any>(false);
  const [compliances, setCompliances] = useState<Compliance[]>([]);

  const getQuickSetting = async () => {
    try {
      const rs = await quickSettingsServices.detail();
      setQuickSetting(rs);
      formQuickSetting.setFieldsValue(rs);
    } catch (error) {}
  };

  const getMaterials = async () => {
    setLoading(true);
    try {
      const tableFilters: TableMaterialFilter = {
        sortDir: tableParams.order !== "descend" ? "ASC" : "DESC",
        sortProperty: tableParams.field?.toString(),
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
        text,
        thickness,
      };
      const rs: TableData = await materialServices.list(tableFilters);
      setData(rs.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: rs.totalItems,
        },
      });
    } catch (error) {}
    setLoading(false);
  };

  const getMaterialGroups = async () => {
    try {
      const rs = await materialServices.getMaterialGroups({
        size: 50,
        sortDir: "ASC",
        sortProperty: "name",
      });
      setMaterialGroups(rs.data);
    } catch (error) {}
  };
  const getThickness = async () => {
    try {
      const rs = await thicknessServices.list({
        size: 1000,
      });
      setListThickness(rs.data);
    } catch (error) {}
  };

  const getCompliances = async () => {
    try {
      const rs = await complianceServices.list({
        size: 10000,
      });
      setCompliances(rs.data);
    } catch (error) {}
  };

  useEffect(() => {
    getMaterialGroups();
    getQuickSetting();
    getThickness();
    getCompliances();
  }, []);

  useEffect(() => {
    getMaterials();
  }, [JSON.stringify(tableParams), text, thickness]);

  useEffect(() => {
    if (material) {
      material.complianceIds = material.compliances?.map(c => c.id);
      formMaterial.setFieldsValue(material);
      setShowMaterialSettings(true);
    } else {
      formMaterial.resetFields();
      setShowMaterialSettings(false);
    }
  }, [material]);

  useEffect(() => {
    if (materialGroup) {
      formGroup.setFieldsValue(materialGroup);
    } else {
      formGroup.resetFields();
    }
  }, [materialGroup]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<Material>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getMaterial = async (id: number) => {
    try {
      const rs = await materialServices.detail(id);
      setMaterial(rs);
    } catch (error) {}
  };

  const getMaterialGroup = async (id: number) => {
    try {
      const rs = await materialServices.getMaterialGroupById(id);
      setMaterialGroup(rs);
    } catch (error) {}
  };

  const showModal = (id?: number) => {
    setIsModalOpen(true);
    if (id) {
      getMaterial(id);
    }
  };

  const showModalGroup = (id?: number) => {
    setIsModalGroupOpen(true);
    if (id) {
      getMaterialGroup(id);
    }
  };

  const onSearch = (e: any) => {
    setText(e.target.value || "");
  };

  const onThicknessChanged = (value: any) => {
    setThickness(value);
  };

  const onMaterialSubmitted = async (values: any) => {
    try {
      // values.compliances = compliances.filter(m => values.complianceIds.includes(m.id));
      try {
        if (values.id) {
          await materialServices.update(values);
        } else {
            const rs: Material = await materialServices.create(values);
            getMaterial(rs.id);  
          
        }
      } catch (error: any) {
        if (error.errorCode == "material.UK_number") {
          const errorMsg = [{ path: 'number', message: t(error?.errorCode) }];
          setFormErrors(formMaterial, errorMsg);
          return
        }
      }

      getMaterials();

      api.success({
        message: `Success!`,
        description: `Material - ${values.name}`,
        placement: "topRight",
      });

      setShowMaterialSettings(true);
      if (values.id) {
        handleCancel();
      }
    } catch (error) {
      api.error({
        message: `Failed!`,
        description: `Material - ${values.name}`,
        placement: "topRight",
      });
    }
  };

  const onGroupSubmitted = async (values: MaterialGroup) => {
    try {
      await materialServices.updateMaterialGroups(values);
      data
        .filter((g) => g.groupId == values.id)
        .map((g) => {
          g.group.cuttingParams = values.cuttingParams;
          g.group.localizations = values.localizations;
        });
      setData([...data]);
      api.success({
        message: `Success!`,
        description: (
          <>
            Material group -{" "}
            <LocalizationName localizations={values.localizations} />
          </>
        ),
        placement: "topRight",
      });
      handleGroupCancel();
    } catch (error) {}
  };

  const handleCancel = () => {
    setMaterial(undefined);
    setIsModalOpen(false);
  };

  const handleGroupCancel = () => {
    setMaterialGroup(undefined);
    setIsModalGroupOpen(false);
  };

  const onFileChanged = async (info: UploadChangeParam<UploadFile<any>>) => {
    setUploading(true);
    try {
      await materialServices.importMaterial(info.file);
      await getMaterials();
      api.success({
        message: "Import success!",
        description: "Done!",
        duration: 20,
        placement: "topRight",
      });
    } catch (e: any) {
      api.error({
        message: e.error,
        description: e.message,
        duration: 20,
        placement: "topRight",
      });
    }
    setUploading(false);
  };
  const exportMaterial = async () => {
    try {
      const data = await materialServices.exportMaterial();
      // Creating new object of PDF file
      const blob = new Blob([data], { type: "text/csv" });
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = `material-${Date.now()}.csv`;
      alink.click();
    } catch (error) {}
  };

  const columns: ColumnsType<Material> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("material.table.group"),
      dataIndex: "group",
      key: "group_name",
      sorter: true,
      render: (data, record, index) => {
        return (
          <Button
            type="primary"
            onClick={showModalGroup.bind(null, record.groupId)}
          >
            <LocalizationName
              localizations={data?.localizations}
              valueDefault={data?.name}
            />
          </Button>
        );
      },
    },
    {
      title: t("material.table.cuttingParams"),
      dataIndex: "group",
      key: "group_cuttingParams",
      sorter: true,
      render: (data) => <NumberFormat value={data?.cuttingParams} />,
    },
    {
      title: t("material.table.name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("material.table.number"),
      dataIndex: "number",
      sorter: true,
      render: (data) => <MaterialNumber value={data} />,
    },
    {
      title: t("material.table.density"),
      dataIndex: "density",
      sorter: true,
      render: (data) => (
        <>
          <NumberFormat value={data} /> g/cm³
        </>
      ),
    },
    {
      title: t("material.table.pricePerKilo"),
      dataIndex: "pricePerKilo",
      sorter: true,
      render: (data) => (
        <>
          <NumberFormat value={data} /> €/kg
        </>
      ),
    },
    {
      title: t("status"),
      dataIndex: "active",
      sorter: true,
      render: (data) =>
        data ? (
          <Tag icon={<CheckOutlined />} color="success">
            {t("active")}
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            {t("inactive")}
          </Tag>
        ),
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 200,
      render: (_: any, record: Material) => {
        const actionBtn = {
          label: t("edit"),
          key: "unarchive",
          icon: <EditOutlined />,
          onClick: () => showModal.bind(null, record.id),
        };

        const columnActionBtn = [
          {
            label: t("delete"),
            key: "delete",
            icon: <DeleteOutlined style={{ fontWeight: 800, fontSize: 17 }} />,
            onClick: () => handleDelete(record.id),
          },
        ];

        return (
          <Dropdown.Button
            icon={<MoreOutlined />}
            menu={{ items: columnActionBtn }}
            placement="bottomRight"
            onClick={actionBtn.onClick()}
          >
            {actionBtn.icon} {actionBtn.label}
          </Dropdown.Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (materialDeleteId) {
      setIsOpenDeleteModal(true);
    } else {
      setIsOpenDeleteModal(false);
    }
  }, [materialDeleteId]);

  const handleDelete = async (projectId: any) => {
    setMaterialDeleteId(projectId);
  };

  const performDeleteAction = async (id: any) => {
    try {
      setLoading(true);
      const rs: Material = await materialServices.deleteMatrial(id);
      api.success({
        message: t("deleted"),
        description: t("deletedNotification"),
        placement: "topRight",
      });
      getMaterials();
      setMaterialDeleteId(undefined);
    } catch (error: any) {
      let msg = t("deletedFailed");
      if (error.errorCode == "MATERIAL_BEING_USED") {
        msg = t("popup.deleteMaterial.beingUsed");
      }

      api.error({
        message: `Error!`,
        description: msg,
        placement: "topRight",
      });
    }
    setLoading(false);
    setIsOpenDeleteModal(false);
    setMaterialDeleteId(undefined);
  };

  const onQuickSettingSubmit = async (values: any) => {
    if (quickSetting) {
      quickSetting.lwhsShape = values.lwhsShape;
      quickSetting.outerDiameterShape = values.outerDiameterShape;
      try {
        await quickSettingsServices.update(quickSetting);
        setQuickSetting({
          ...quickSetting,
          ...values,
        });
        api.success({
          message: `Update success!`,
          placement: "topRight",
        });
        // formQuickSetting.setFieldsValue(quickSetting);
        setIsEditQuickSettings(false);
      } catch (error) {}
    }
  };

  return (
    <>
      {modalContextHolder}
      {contextHolder}
      <h3>{t("material.settings")}</h3>
      {config.shopType !== ShopType.SHEET_METAL && (
        <Form
          form={formQuickSetting}
          layout="vertical"
          onFinish={onQuickSettingSubmit}
        >
          <div className="row mb-3 gap-3 gap-md-0">
            <div className="col col-12 col-md-5 col-xl-3 ">
              <Form.Item
                className="mb-0"
                name="outerDiameterShape"
                messageVariables={{
                  label: t("material.setting.outerDiameterShape.label"),
                }}
                label={
                  <Space>
                    <label
                      dangerouslySetInnerHTML={{
                        __html: t("material.setting.outerDiameterShape"),
                      }}
                    ></label>
                    <Popover
                      overlayInnerStyle={{ width: "40vw" }}
                      placement="right"
                      content={
                        <>
                          <img
                            width={"100%"}
                            src="/images/Rund-Zuschlag-Rohmaterial.jpg"
                            alt="ToleranzmaBe_Information_Bild"
                          ></img>
                        </>
                      }
                      trigger="hover"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </Space>
                }
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={!isEditQuickSettings}
                  addonAfter="mm"
                  min={0}
                />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-5 col-xl-3">
              <Form.Item
                className="mb-0"
                name="lwhsShape"
                messageVariables={{
                  label: t("material.setting.lwhsShape.label"),
                }}
                label={
                  <Space>
                    <label>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t("material.setting.lwhsShape"),
                        }}
                      ></label>
                    </label>
                    <Popover
                      overlayInnerStyle={{ width: "40vw" }}
                      placement="right"
                      content={
                        <>
                          <img
                            width={"100%"}
                            src="/images/Rechteck-Zuschlag.jpg"
                            alt="ToleranzmaBe_Information_Bild"
                          ></img>
                        </>
                      }
                      trigger="hover"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </Space>
                }
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={!isEditQuickSettings}
                  addonAfter="mm"
                  min={0}
                />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-auto d-flex align-items-end">
              {!isEditQuickSettings && (
                <Button
                  onClick={setIsEditQuickSettings.bind(null, true)}
                  className="small"
                >
                  <EditOutlined />
                </Button>
              )}
              {isEditQuickSettings && (
                <Space>
                  <Button
                    className="small"
                    danger
                    onClick={setIsEditQuickSettings.bind(null, false)}
                  >
                    <CloseOutlined />
                  </Button>
                  <Button type="primary" htmlType="submit" className="small">
                    <CheckOutlined />
                  </Button>
                </Space>
              )}
            </div>
          </div>
        </Form>
      )}
      <div className="d-flex gap-3 mb-3 w-100 app-form">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal.bind(null, 0)}
        >
          {t("material.add")}
        </Button>
        <Upload
          accept="text/csv"
          fileList={[]}
          onChange={onFileChanged}
          beforeUpload={() => {
            return false;
          }}
        >
          <Button icon={<UploadOutlined />} type="primary" loading={uploading}>
            {" "}
            {t("material.importCSV")}
          </Button>
        </Upload>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={exportMaterial}
        >
          {t("material.exportCSV")}
        </Button>
        <Input
          className="ms-auto"
          style={{
            maxWidth: "20vw",
          }}
          prefix={<SearchOutlined />}
          placeholder={t("material.search.placeholder") || ""}
          allowClear
          onChange={onSearch}
        />
      </div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `material-${record.id}`}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Material"
        open={isModalOpen}
        onOk={formMaterial.submit}
        onCancel={handleCancel}
        className="material-modal modal-body-scroll"
        destroyOnClose
      >
        <Form
          className="app-form"
          form={formMaterial}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onMaterialSubmitted}
          initialValues={{
            active: true,
          }}
        >
          <div className="row">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="group" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="groupId"
              label={t("material.table.group")}
              rules={[{ required: true }]}
            >
              <Select placeholder={t("pleaseSelect")}>
                {materialGroups?.map((item) => (
                  <Option key={`group-${item.id}`} value={item.id}>
                    <LocalizationName
                      localizations={item.localizations}
                      valueDefault={item.name}
                    />
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="col col-12">
              <Form.Item
                label={t("material.table.name")}
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
            {/* <div className="col col-12">
              <LocalizationFormItem form={formMaterial} data={material} />
            </div> */}
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("material.table.number")}
                name="number"
                rules={ruleMaterialNumber}
              >
                <Input maxLength={20} placeholder="3.1234A" />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("material.table.density")}
                name="density"
                rules={[{ required: true }]}
              >
                <InputNumber addonAfter="g/cm³" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("material.table.pricePerKilo")}
                name="pricePerKilo"
                rules={[{ required: true }]}
              >
                <InputNumber addonAfter="€/kg" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("menu.compliances")}
                name="complianceIds"
              >
                <Select showSearch optionFilterProp="children" mode="multiple" placeholder={t("pleaseSelect")}>
                  {compliances.map((item) => (
                    <Option key={`compliances-${item.id}`} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("status")}
                name="active"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={t("active")}
                  unCheckedChildren={t("inactive")}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        {showMaterialSettings && (
          <>
            {config.shopType !== ShopType.MILLING_AND_TURNING && (
              <ThicknessView material={material} />
            )}
            {config.shopType !== ShopType.SHEET_METAL && (
              <RawMaterialView material={material} />
            )}
          </>
        )}
      </Modal>
      <Modal
        title={t("material.table.group")}
        open={isModalGroupOpen}
        onOk={formGroup.submit}
        onCancel={handleGroupCancel}
        destroyOnClose
      >
        <Form
          form={formGroup}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onGroupSubmitted}
        >
          <div className="row">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            {/* <Form.Item label={t("material.table.group")} name="name">
              <Input disabled />
            </Form.Item> */}
            <LocalizationFormItem form={formGroup} data={materialGroup} />
            <Form.Item name="abbr" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="active" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("material.table.cuttingParams")}
              name="cuttingParams"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        open={isOpenDeleteModal}
        title={t("popup.deleteOffer.title")}
        onCancel={setMaterialDeleteId.bind(null, undefined)}
        onOk={performDeleteAction.bind(null, materialDeleteId)}
        okButtonProps={{
          loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
      >
        <p>{t("popup.deleteMaterial.message")}</p>
      </Modal>
    </>
  );
};

export default MaterialPage;
