import axios from "axios";
import services from ".";
import { API } from "../constants/api.constant";
import { TableFilter } from "../models/Table";

export const getCountries = async (params?: any[]) => {
  try {
    const rs = await axios.get(API.COUNTRIES);
    if(params) {
      return rs.data.filter((c: any) => params.includes(c.iso2))
    }
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const countryServices = {
  getCountries
};

export default countryServices;
