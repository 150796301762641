import { Compliance } from "app/models/Compliance";
import services from ".";
import { API } from "../constants/api.constant";
import { TableFilter } from "../models/Table";

export const create = async (data: Compliance) => {
  try {
    const rs = await services.post(API.COMPLIANCE, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const list = async (params: TableFilter) => {
  try {
    const rs = await services.get(API.COMPLIANCE, { params });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const detail = async (id: number) => {
  try {
    const rs = await services.get(
      API.COMPLIANCE_BY_ID.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: Compliance) => {
  try {
    if (data.id) {
      const rs = await services.put(API.COMPLIANCE, data);
      return rs.data;
    }
  } catch (error) {
    throw error;
  }
};

const complianceServices = {
  create,
  detail,
  list,
  update,
};

export default complianceServices;
